import * as React from "react";
import Layout from "../components/global/layout/layout";
import {graphql} from "gatsby";
import Link from "../components/global/link/link";
import SmallHeader from "../components/global/small-header/small-header";

export const pageQuery = graphql`
  query ImprintQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`;

export default props => {
  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}
            titleSuffix="Impressum"
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <SmallHeader title={'Impressum'}
                   backgroundImage={'/images/global/weber-schild.jpg'}/>
      <div className="container mt-5">
        <div className="row">
          <div className="col-12">
            <p>
              Wir freuen uns, dass Sie unsere Webseiten besuchen und bedanken uns für Ihr Interesse
              an unserer Dienstleistung und unseren Webseiten. Der Schutz Ihrer Privatsphäre bei der
              Nutzung unserer Webseiten ist uns wichtig. Daher nehmen Sie bitte nachstehende
              Informationen sowie unsere <Link to="/datenschutz" className="unstyled font-weight-bold">Datenschutzerklärung</Link> zur Kenntnis.
            </p>
            <h2>Anschrift</h2>
            <address>
              Psychotherapie Anna Weber-Nilges<br></br>
              Steinweg 30<br></br>
              57627 Hachenburg
            </address>
            <h2>Kontakt</h2>
            <p>
              <a className="unstyled" href="tel:+4926629502797">Tel. +49 2662 9502 797</a><br></br>
              <a className="unstyled" href="mailto:info@psychotherapie-weber.nete">E-Mail: info@psychotherapie-weber.net</a><br></br>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}
